<template>
  <div id="dialogMaintenanceMode">
<!----------------------------------- Dialog Maintenance Mode ----------------------------->
    <v-dialog v-model="dialog" width="600px" persistent @keydown.esc="agree">
      <v-card>
<!----------------------------------- Title Dialog ------------------------------->
        <v-card-title>
          <span class="headline">
            <v-icon 
              left large color="primary darken-2">
              mdi-cogs </v-icon>
              {{$t('dialogMaintenanceMode.title')}}
          </span>
        </v-card-title>
<!----------------------------------- Content Dialog ----------------------------->
        <v-card-text> {{ wordContent }} </v-card-text>
<!----------------------------------- Action Dialog ------------------------------>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            v-on:click="agree"
          >
            {{$t('common.btnClose')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  /* Name of Dialog Maintenance Mode */
  name: "dialogMaintenanceMode",

  /* Props of Dialog Maintenance Mode */
  props: {
    /* Word Cotent */
    wordContent: {
      type: String,
      default: "",
    },
  },

  /* Data of Dialog MaintenanceMode */
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
    };
  },

  /* Methods of Dialog Maintenance Mode */
  methods: {
    /********************************* Global Function *********************************/
    /* Display Content */
    displayContent: function () {
      return this._displayContent();
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },    
    /********************************* Callback Function *******************************/
    /* When Click Hide Dialog MaintenanceMode */
    onClickHideDialogMaintenanceMode: function () {
      /* Send Emit Event onClickHideDialogMaintenanceMode */
      this.$emit("onClickHideDialogMaintenanceMode");
      /* Hide Content */
      this._hideContent();
    },
    /********************************* Local Function **********************************/
    /********************************* Utility Function ********************************/
    /* Display Content */
    _displayContent: function () {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    /* Hide Content */
    _hideContent: function () {
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>