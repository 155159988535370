/*
|-------------------------------------------------------------------------------
| VUEX modules/information.store.js
|-------------------------------------------------------------------------------
| The Vuex data store for the Access Feature
*/
/* Import Store */
import store from "../index";

const mDefaultState = () => {
  return {
    /* Raw Data */
    driverImeiList: [],
    groupList: [],
    groupSettingList: [],
    userList: [],
    geoFenceList: [],
    lastestLocationList: [],
    /* Selected Account */
    selectedAccount: [],
    /* Selected Account Status List */
    selectedAccountStatusList: {},
    /* Report Data */
    dailyReportData: [],
    overviewDailyReportData: [],
    geofenceReportData: [],
    historyOverviewDailyData: [],
    dailyReportSelection: {
      fromDate: "",
      toDate: "",
      group: "",
      userList: [],
    },
    overviewDailyReportSelection: {
      date: "",
      group: "",
      userList: [],
    },
    geofenceReportSelection: {
      fromDate: "",
      toDate: "",
      group: "",
      userList: [],
    },
    /* History List */
    historyList: [],
    /* Selected History */
    selectedHistory: {
      group: "",
      driveraccount: "",
      to_time: 0,
      from_time: 0,
      download: true,
    },
    pageTokenHistory: "",
  };
};

/* Defines the state being monitored for the module. */
const state = mDefaultState();

/* Defines the ters used by the module */
const getters = {
  /* Information */
  driverImeiList: (state) => state.driverImeiList,
  groupList: (state) =>
    state.groupList.sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }),
  groupSettingList: (state) =>
    state.groupSettingList.sort(function (a, b) {
      if (a.group < b.group) return -1;
      if (a.group > b.group) return 1;
      return 0;
    }),
  groupListParamQuery: (state) => {
    let _param = "";
    let _groupList = state.groupList;
    let _lengthGroupList = _groupList.length;
    for (let index = 0; index < _lengthGroupList; index++) {
      if (0 == index) _param = `?group=${_groupList[index]}`;
      else _param += `,${_groupList[index]}`;
    }
    return _param;
  },
  userList: (state) => state.userList,
  geoFenceList: (state) => state.geoFenceList,
  lastestLocationList: (state) => {
    let _listLatestLocation = [...state.lastestLocationList];
    let _lengthListLatestLocation = _listLatestLocation.length;
    for (let index = 0; index < _lengthListLatestLocation; index++) {
      if (undefined == _listLatestLocation[index]) continue;
      if (undefined == _listLatestLocation[index].speed) continue;
      _listLatestLocation[index].speed = Number(_listLatestLocation[index].speed).toFixed(0);
    }
    return _listLatestLocation;
  },
  isAllowPolicy: (state) => {
    let _listUser = state.userList;
    let _username = store.getters["username"];
    let _lengthListUser = _listUser.length;
    for (let index = 0; index < _lengthListUser; index++) {
      if (_username != _listUser[index].account) continue;
      let _isAllowPolicy = _listUser[index].isAllowPolicy;
      if (undefined == _isAllowPolicy || null == _isAllowPolicy) return false;
      return _isAllowPolicy;
    }
    return true;
  },
  /* Selected Account */
  selectedAccount: (state) => state.selectedAccount,
  groupSelectedAccountParamQuery: (state) => {
    let _param = "";
    let _groupSelected = [];
    let _selectedAccount = state.selectedAccount;
    let _lengthSelectedAccount = _selectedAccount.length;
    for (let index = 0; index < _lengthSelectedAccount; index++) {
      _groupSelected.push(_selectedAccount[index].group);
    }
    let _uniqueGroup = [...new Set(_groupSelected)];
    let _lengthUniqueGroup = _uniqueGroup.length;
    for (let index = 0; index < _lengthUniqueGroup; index++) {
      if (0 == index) _param = `?group=${_uniqueGroup[index]}`;
      else _param += `,${_uniqueGroup[index]}`;
    }
    return _param;
  },
  driverAccountSelectedAccountParamQuery: (state) => {
    let _param = "";
    let _driverAccountSelected = [];
    let _selectedAccount = state.selectedAccount;
    let _lengthSelectedAccount = _selectedAccount.length;
    for (let index = 0; index < _lengthSelectedAccount; index++) {
      _driverAccountSelected.push(_selectedAccount[index].driveraccount);
    }
    let _uniqueDriverAccount = [...new Set(_driverAccountSelected)];
    let _lengthUniqueDriverAccount = _uniqueDriverAccount.length;
    for (let index = 0; index < _lengthUniqueDriverAccount; index++) {
      if (0 == index)
        _param = `?driveraccount_list=${_uniqueDriverAccount[index]}`;
      else _param += `,${_uniqueDriverAccount[index]}`;
    }
    return _param;
  },
  /* Selected Account Status List */
  selectedAccountStatusList: (state) => state.selectedAccountStatusList,
  /* Report Data */
  dailyReportData: (state) => state.dailyReportData,
  overviewDailyReportData: (state) => state.overviewDailyReportData,
  geofenceReportData: (state) => state.geofenceReportData,
  historyOverviewDailyData: (state) => state.historyOverviewDailyData,
  dailyReportSelection: (state) => state.dailyReportSelection,
  overviewDailyReportSelection: (state) => state.overviewDailyReportSelection,
  geofenceReportSelection: (state) => state.geofenceReportSelection,
  /* History List */
  historyList: (state) => {
    let _listHistory = [...state.historyList];
    let _lengthListHistory = _listHistory.length;
    for (let index = 0; index < _lengthListHistory; index++) {
      if (undefined == _listHistory[index]) continue;
      if (undefined == _listHistory[index].speed) continue;
      _listHistory[index].speed = Number(_listHistory[index].speed).toFixed(0);
    }
    return _listHistory;
  },
  pageTokenHistory: (state) => state.pageTokenHistory,
  /* Selected History */
  selectedHistory: (state) => state.selectedHistory,
  selectedHistoryParamQueury: (state) => {
    let _param = `?group=${state.selectedHistory.group}&driveraccount=${state.selectedHistory.driveraccount}&to_time=${state.selectedHistory.to_time}&from_time=${state.selectedHistory.from_time}&page_token=${state.pageTokenHistory}&download=${state.selectedHistory.download}`;
    return _param;
  },
  selectedHistoryParamQueuryNoPageToken: (state) => {
    let _param = `?group=${state.selectedHistory.group}&driveraccount=${state.selectedHistory.driveraccount}&to_time=${state.selectedHistory.to_time}&from_time=${state.selectedHistory.from_time}&download=${state.selectedHistory.download}`;
    return _param;
  },
  selectedHistoryCustomAttribute: (state) => {
    let _selectedHistory = state.selectedHistory;
    let _userList = state.userList;
    let _lengthUserList = _userList.length;
    let _driverImeiList = state.driverImeiList;
    let _lengthDriverImeiList = _driverImeiList.length;
    for (let index = 0; index < _lengthUserList; index++) {
      if (_selectedHistory.driveraccount != _userList[index].account) continue;
      return _userList[index].attribute;
    }
    for (let index = 0; index < _lengthDriverImeiList; index++) {
      if (
        _selectedHistory.driveraccount != _driverImeiList[index].driveraccount
      )
        continue;
      return _driverImeiList[index].attribute;
    }
    return {};
  },
};

/* Defines the mutations used */
const mutations = {
  /* Raw Data */
  driverImeiList: (state, driverImeiList) =>
    (state.driverImeiList = driverImeiList),
  groupList: (state, groupList) => (state.groupList = groupList),
  groupSettingList: (state, groupSettingList) => (state.groupSettingList = groupSettingList),
  userList: (state, userList) => (state.userList = userList),
  geoFenceList: (state, geoFenceList) => (state.geoFenceList = geoFenceList),
  lastestLocationList: (state, lastestLocationList) =>
    (state.lastestLocationList = lastestLocationList),
  /* Selected Account */
  selectedAccount: (state, selectedAccount) =>
    (state.selectedAccount = selectedAccount),
  /* Selected Account  */
  selectedAccountStatusList: (state, selectedAccountStatusList) =>
    (state.selectedAccountStatusList = selectedAccountStatusList),
  /* Report Data */
  dailyReportData: (state, dailyReportData) =>
    (state.dailyReportData = dailyReportData),
  overviewDailyReportData: (state, overviewDailyReportData) =>
    (state.overviewDailyReportData = overviewDailyReportData),
  geofenceReportData: (state, geofenceReportData) =>
    (state.geofenceReportData = geofenceReportData),
  historyOverviewDailyData: (state, historyOverviewDailyData) =>
    (state.historyOverviewDailyData = historyOverviewDailyData),
  dailyReportSelection: (state, dailyReportSelection) =>
    (state.dailyReportSelection = dailyReportSelection),
  overviewDailyReportSelection: (state, overviewDailyReportSelection) =>
    (state.overviewDailyReportSelection = overviewDailyReportSelection),
  geofenceReportSelection: (state, geofenceReportSelection) =>
    (state.geofenceReportSelection = geofenceReportSelection),
  /* History List */
  historyList: (state, historyList) => (state.historyList = historyList),
  /* Selected History */
  selectedHistory: (state, selectedHistory) =>
    (state.selectedHistory = selectedHistory),
  pageTokenHistory: (state, pageTokenHistory) =>
    (state.pageTokenHistory = pageTokenHistory),
  /* Reset State */
  resetState: (state) => Object.assign(state, mDefaultState()),
};

/* Export */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

/* END OF FILE */
