/*
|-------------------------------------------------------------------------------
| VUEX modules/dataSetting.store.js
|-------------------------------------------------------------------------------
| The Vuex data store for the Setting
*/

/* Constnat of Updating */
const cMaxUpdating = 20;

const mDefaultState = () => {
  return {
    maxUpdating: cMaxUpdating,
  };
};

/* Defines the state being monitored for the module. */
const state = mDefaultState();

/* Defines the getters used by the module */
const getters = {
  /* Returns the Max Updating */
  getMaxUpdating: (state) => state.maxUpdating,
};

/* Defines the mutations used */
const mutations = {
  /* Set the Max Updating */
  setMaxUpdating: (state, data) => (state.maxUpdating = data),
  /* Reset State */
  resetState: (state) => Object.assign(state, mDefaultState()),
};

/* Defines the actions used to retrieve the MaxUpdating. */
const actions = {
  setMaxUpdating: ({ commit }, data) => commit("setMaxUpdating", data),
};

/* Export */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

/* END OF FILE */
