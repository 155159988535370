/* Import Material App */
/* Import VueJs */
import Vue from "vue";
/* Import App Main */
import App from "./App.vue";
/* Import Vuex */
/* Import Router */
import router from "./router";
/* Import Store */
import store from "./store";
/* Import Third Party */
/* Import Axios */
import axios from "axios";
/* Import Vuetify */
import vuetify from "./plugins/vuetify";
/* Import Vue Google Maps */
import * as VueGoogleMaps from "vue2-google-maps";
/* Import Firebase */
import "./plugins/firebase-config";

/* Import VueJS Logger Plugin */
import Logger from "./plugins/logger";

/* Component of Boostap-Vue */
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import i18n from "./i18n";

/* Vue-Browser-GeoLocation */
import VueGeolocation from "vue-browser-geolocation";
/* Vue-Mobile-Detection */
import VueMobileDetection from "vue-mobile-detection";

/* Constant Map Config */
const mapcfg = {
  installComponents: true,

  load: {
    key: "AIzaSyBZE8-cNB8QVsD0VQM2VaGxnCYHpP5kJpM",
    libraries: "places,geometry",
  },
};

/* Install BootstrapVue */
Vue.use(BootstrapVue);
/* Optionally install the BootstrapVue icon components plugin */
Vue.use(IconsPlugin);
/* Initial Vue Google Maps */
Vue.use(VueGoogleMaps, mapcfg);
/* Initial Geolocation on Browser */
Vue.use(VueGeolocation);
/* Initial MobileDetection on Browser */
Vue.use(VueMobileDetection);

/* Disable Production Tip */
Vue.config.productionTip = false;
/* Vue Add Prototype */
Vue.prototype.$http = axios;
Vue.config.devtools = true;

/* Create VueJs Project of Shinki Bus */
new Vue({
  router,
  store,
  vuetify,
  i18n,
  Logger,
  render: (h) => h(App),
}).$mount("#app");
