<template>
  <div id="dialogErrorRetry">
<!----------------------------------- Dialog Error Retry ----------------------------->
    <v-dialog v-model="dialog" width="600px" persistent @keydown.esc="cancel">
      <v-card>
<!----------------------------------- Title Dialog ------------------------------->
        <v-card-title>
          <span class="headline">
            <v-icon 
              left large color="red darken-2">
              mdi-alert-circle </v-icon>
              {{$t('dialogErrorRetry.title')}}
          </span>
        </v-card-title>
<!----------------------------------- Content Dialog ----------------------------->
        <v-card-text> {{ wordContent }} </v-card-text>
<!----------------------------------- Action Dialog ------------------------------>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="red"
            v-on:click="retry"
            v-show="isRetryVisible"
          >
            {{$t('dialogErrorRetry.btnRetry')}}
          </v-btn>
          <v-btn
            text
            color="grey"
            v-on:click="cancel"
          >
            {{$t('common.btnClose')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  /* Name of Dialog Error Retry */
  name: "dialogErrorRetry",

  /* Props of Dialog Error Retry */
  props: {
    /* Word Cotent */
    wordContent: {
      type: String,
      default: "",
    },
    isRetryVisible:{
      type: Boolean,
      default: true,
    }
  },

  /* Data of Dialog Error Retry */
  data() {
    return {
      /* Flag Display Dialog */
      dialog: false,
      /* Dialog Return Result*/
      resolve: null,
      reject: null,
    };
  },

  /* Methods of Dialog Error Retry */
  methods: {
    /********************************* Global Function *********************************/
    /* Display Content */
    displayContent: function () {
      return this._displayContent();
    },
    retry() {
      this.resolve(true);
      this.dialog = false;
    },   
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },  
    /********************************* Callback Function *******************************/
    /* When Click Hide Dialog Error Retry */
    onClickHideDialogErrorRetry: function () {
      /* Send Emit Event onClickHideDialogErrorRetry */
      this.$emit("onClickHideDialogErrorRetry");
      /* Hide Content */
      this._hideContent();
    },
    /********************************* Local Function **********************************/
    /********************************* Utility Function ********************************/
    /* Display Content */
    _displayContent: function () {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    /* Hide Content */
    _hideContent: function () {
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>