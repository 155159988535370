import Vue from "vue";
import VueLogger from 'vuejs-logger';
const isProduction = process.env.NODE_ENV === 'production';

/*  option */
/*  isEnabled           = Enables the vuejs-logger plugin, useful toggle for production/development. */
/*  logLevel            = Choose between ['debug', 'info', 'warn', 'error', 'fatal']. Read production tips. */
/*  stringifyArguments  = If true, all input will go through JSON.stringify(). Useful when printing reactive properties. */
/*  showLogLevel        = If true, the loglevel will be shown [logLevel]. */
/*  showMethodName      = If true, the method name of the parent function will be shown in the console. */
/*  separator           = The seperator between parts of the output. */
/*  showConsoleColors   = If true, enables console.warn, console.fatal, console.error for corresponding loglevels. */

/*  Production tips */
/*  The plugin can be disabled for production or a lower logLevel can be set to minimize output.                        */
/*  If the logLevel is set to 'fatal' the plugin will ignore all calls with less important loglevels in the code.       */

const options = {
  isEnabled: true,                     
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};

/* Register Vuejs-Logger Plugin */
Vue.use(VueLogger, options);

/* Export Defaluter VueLogger */
export default VueLogger