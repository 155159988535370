/* Import Vue */
import Vue from "vue";
/* Import Vue Router */
import VueRouter from "vue-router";
/* Import Store */
import store from "@/store/index";

/* Set Vue Router to Vue */
Vue.use(VueRouter);

/* Create Routes that using controller pages */
const routes = [
  /* Login Page */
  {
    path: "/loginPage",
    name: "LoginPage",
    component: () => import("../views/loginPage"),
  },
  /* Main Page */
  {
    path: "/mainPage",
    name: "MainPage",
    component: () => import("../views/mainPage"),
  },
  /* Privacy Policy Page */
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: () => import("../views/privacyPolicyPage"),
  },
];

/* Create Router of Vue Router that using history mode display on base url */
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/* Export Default Router */
export default router;

router.beforeEach((to, _, next) => {
  /* Add Before Each Router */
  if ("/loginPage" != to["path"]) {
    if ("/loginPage" == _["path"]) {
      if (false == store.getters.signedIn) next({ path: "/loginPage" });
      next();
    } else {
      if ("/privacy" == to["path"]) next();
      else next({ path: "/loginPage" });
    }
  } else {
    next();
  }
});
