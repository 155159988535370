/* Import Vue */
import Vue from "vue";
/* Import Vuex */
import Vuex from "vuex";

/* Imports all of the modules used in the application to build the data store. */
import configure from "@/store/modules/configure";
import information from "@/store/modules/information";
import setting from "@/store/modules/setting";
import dataSetting from "@/store/modules/dataSetting";

/* Set Vuex to Vue Project */
Vue.use(Vuex);

/* Export Deault Store of Vuex */
export default new Vuex.Store({
  modules: {
    configure,
    information,
    setting,
    dataSetting,
  },
});
