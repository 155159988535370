<template>
  <div id="snackBarReload">
<!--------------------------------- Loading Snackbar --------------------------------->
    <v-snackbar
      v-bind:timeout="-1"
      v-model="mIsDisplaySnackbar"
      rounded="pill"
      color="blue-grey"
      top
    >
      <center>
        <v-progress-circular
          class="mr-3"
          indeterminate
          color="white"
          size="17"
        ></v-progress-circular>
        <strong> Please wait a moment</strong>
      </center>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  /* Name of Snack Bar Reload */
  name: "snackBarReload",
  /* Data of Snack Bar Reload */
  data: function () {
    return {
      /* Flag display Snackbar */
      mIsDisplaySnackbar: false,
    };
  },

  /* Methods of Snack Bar Reload */
  methods: {
    /********************************* Global Function *********************************/
    /* Display Loading Snackbar */
    displayLoadingSnackbar: function () {
      this._displayLoadingSnackbar();
    },

    /* Close Loading Snackbar */
    closeLoadingSnackbar: function () {
      this._closeLoadingSnackbar();
    },

    /********************************* Local Function **********************************/
    /* Display Loading Snackbar */
    _displayLoadingSnackbar: function () {
      this.mIsDisplaySnackbar = true;
    },

    /* Close Loading Snackbar */
    _closeLoadingSnackbar: function () {
      this.mIsDisplaySnackbar = false;
    },
  },
};
</script>

<style>
</style>