<template>
  <div id="appMain">
<!-------------------------------- App Main Page ----------------------------->
    <v-app>
<!-------------------------------- Main -------------------------------------->
      <v-main>
<!-------------------------------- Snack Bar Reload -------------------------->
        <snack-bar-reload ref="refSnackBarReload" />
<!-------------------------------- HTTP Common ------------------------------->
        <http-common
          v-on:onGetSuperAdminDriverImei="onGetSuperAdminDriverImei"
          v-on:onGetSuperAdminGroupSetting="onGetSuperAdminGroupSetting"
          v-on:onGetSuperAdminUser="onGetSuperAdminUser"
          v-on:onGetSuperAdminGeoFence="onGetSuperAdminGeoFence"
          v-on:onGetAdminDriverImei="onGetAdminDriverImei"
          v-on:onGetAdminUser="onGetAdminUser"
          v-on:onGetAdminGeoFence="onGetAdminGeoFence"
          v-on:onGetDefaultDriverImei="onGetDefaultDriverImei"
          v-on:onCallbackExit="onCallbackExit"
          ref="httpCommon"
        />
        <router-view
          ref="refView"
          v-on:onClickLogout="onClickLogout"
          v-on:onOpenSnackBarReload="onOpenSnackBarReload"
          v-on:onCloseSnackBarReload="onCloseSnackBarReload"
          v-on:onReloadConfiguration="onReloadConfiguration"
        />
      </v-main>
    </v-app>
  </div>
</template>

<script>
/* Import Components */
import HttpCommon from "@/components/httpCommon.vue";
import SnackBarReload from "@/components/snackBarReload";

export default {
  /* Name of App Main */
  name: "AppMain",

  /* Components of App Main */
  components: {
    HttpCommon,
    SnackBarReload,
  },

  /* Datas of App Main */
  data: function () {
    return {
      /* Flag Check Error Request */
      mIsErrorRequest: false,
    };
  },

  /* Methods of App Main */
  methods: {
    /********************************* Callback Function ******************************/
    /* When click Logout */
    onClickLogout: function () {
      this._clearStores();
      this.$router.push({ path: "/loginPage" });
    },

    /* When Open Snack Bar Reload */
    onOpenSnackBarReload: function () {
      this._displaySnackBarReload();
    },

    /* When Open Snack Bar Reload */
    onCloseSnackBarReload: function () {
      this._closeSnackBarReload();
    },

    /* When Reload Configuration */
    onReloadConfiguration: async function () {
      let _role = this.$store.getters.userCustomRole;
      this._clearEnvironmentWhenReloadConfiguration();
      this._displaySnackBarReload();
      if ("map_admin" == _role) await this._requestInformationMAPAdmin();
      else if ("super_admin" == _role)
        await this._requestInformationSuperAdmin();
      else if ("admin" == _role) await this._requestInformationAdmin();
      else if ("driver" == _role) await this._requestInformationDriver();
      else if ("passenger" == _role) await this._requestInformationPassenger();
      this._closeSnackBarReload();
      this._externalProcessReloadConfiguration();
    },

    /* When Get Super Admin Driver Imei */
    onGetSuperAdminDriverImei: async function (response) {
      this.$store.commit("information/driverImeiList", response.data.result);
      this.$log.info("onGetSuperAdminDriverImei: ");
    },

    /* When Get Super Admin Group Setting */
    onGetSuperAdminGroupSetting: async function (response) {
      let _result = response.data.result;
      let _lengthResult = _result.length;
      let _groupList = []
      for (let index = 0; index < _lengthResult; index++) {
        _groupList.push(_result[index].group);
      }
      this.$store.commit("information/groupList", _groupList);
      this.$store.commit("information/groupSettingList", _result);
      this.$log.info("onGetSuperAdminGroupSetting: ");
    },

    /* When Get Super Admin User */
    onGetSuperAdminUser: async function (response) {
      this.$store.commit("information/userList", response.data.result);
      this.$log.info("onGetSuperAdminUser: ");
    },

    /* When Get Super Admin GeoFence */
    onGetSuperAdminGeoFence: function (response) {
      this.$store.commit("information/geoFenceList", response.data.result);
      this.$log.info("onGetSuperAdminGeoFence: ");
    },

    /* When Get Admin Driver Imei */
    onGetAdminDriverImei: async function (response) {
      this.$store.commit("information/driverImeiList", response.data.result);
      this.$log.info("onGetAdminDriverImei: ");
    },

    /* When Get Admin User */
    onGetAdminUser: async function (response) {
      this.$store.commit("information/userList", response.data.result);
      this.$log.info("onGetAdminUser: ");
    },

    /* When Get Admin GeoFence */
    onGetAdminGeoFence: async function (response) {
      this.$store.commit("information/geoFenceList", response.data.result);
      this.$log.info("onGetAdminGeoFence: ");
    },

    /* When Get Default GeoFence */
    onGetDefaultDriverImei: async function (response) {
      this.$log.info("onGetDefaultDriverImei: ");
      if (true == this._isEmptyObject(response.data.result))
        return this.$store.commit("information/driverImeiList", []);
      this.$store.commit("information/driverImeiList", [response.data.result]);
    },

    /* Exit after http error */
    onCallbackExit: function () {
      this.$log.info("onCallbackExit: ");
      this._setFlagErrorRequest();
    },

    /********************************* Local Function *********************************/
    /* Request Information MAP Admin */
    _requestInformationMAPAdmin: async function () {
      await this._requestGetDriverImeiSuperAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
      await this._requestGetGroupSettingSuperAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
      await this._requestGetUserSuperAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
      await this._requestGetGeoFenceSuperAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
    },

    /* Request Get Driver IMEI Super Admin */
    _requestGetDriverImeiSuperAdmin: async function () {
      let _httpGetInfo = {
        url: this.$store.getters.urlSuperAdminDriverImei,
        apikey: this.$store.getters.apiKey,
        token: this.$store.getters.jwtToken,
        callbackOK: "onGetSuperAdminDriverImei",
        callbackExit: "onCallbackExit",
      };
      await this.$refs.httpCommon.HttpGet(_httpGetInfo);
    },

    /* Request Get Group Setting Super Admin */
    _requestGetGroupSettingSuperAdmin: async function () {
      let _httpGetInfo = {
        url: this.$store.getters.urlSuperAdminGroupSetting,
        apikey: this.$store.getters.apiKey,
        token: this.$store.getters.jwtToken,
        callbackOK: "onGetSuperAdminGroupSetting",
        callbackExit: "onCallbackExit",
      };
      await this.$refs.httpCommon.HttpGet(_httpGetInfo);
    },

    /* Request Get User Super Admin */
    _requestGetUserSuperAdmin: async function () {
      let _httpGetInfo = {
        url: this.$store.getters.urlSuperAdminUser,
        apikey: this.$store.getters.apiKey,
        token: this.$store.getters.jwtToken,
        callbackOK: "onGetSuperAdminUser",
        callbackExit: "onCallbackExit",
      };
      await this.$refs.httpCommon.HttpGet(_httpGetInfo);
    },

    /* Request Get GeoFence Super Admin */
    _requestGetGeoFenceSuperAdmin: async function () {
      let _httpGetInfo = {
        url: this.$store.getters.urlSuperAdminGeoFence,
        apikey: this.$store.getters.apiKey,
        token: this.$store.getters.jwtToken,
        callbackOK: "onGetSuperAdminGeoFence",
        callbackExit: "onCallbackExit",
      };
      await this.$refs.httpCommon.HttpGet(_httpGetInfo);
    },

    /* Request Information Super Admin */
    _requestInformationSuperAdmin: async function () {
      await this._requestGetDriverImeiSuperAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
      await this._requestGetGroupSettingSuperAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
      await this._requestGetUserSuperAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
      await this._requestGetGeoFenceSuperAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
    },

    /* Request Information Admin */
    _requestInformationAdmin: async function () {
      await this._requestGetDriverImeiAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
      this._requestGetGroupSettingAdmin();
      await this._requestGetUserAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
      await this._requestGetGeoFenceAdmin();
      if (true == this._isError()) return this._closeSnackBarReload();
    },

    /* Request Get Driver IMEI  Admin */
    _requestGetDriverImeiAdmin: async function () {
      let _httpGetInfo = {
        url:
          this.$store.getters.urlAdminDriverImei +
          this._getQueryGroupParameter(),
        apikey: this.$store.getters.apiKey,
        token: this.$store.getters.jwtToken,
        callbackOK: "onGetAdminDriverImei",
        callbackExit: "onCallbackExit",
      };
      await this.$refs.httpCommon.HttpGet(_httpGetInfo);
    },

    /* Set Group List Default */
    _setGroupListDefault: async function () {
      let _groupList = [];
      _groupList.push(this.$store.getters.userCustomGroup);
      this.$store.commit("information/groupList", _groupList);
    },

    /* Request Get Group Setting  Admin */
    _requestGetGroupSettingAdmin: async function () {
      let _httpGetInfo = {
        url: this.$store.getters.urlAdminGroupSetting + this._getQueryGroupParameter(),
        apikey: this.$store.getters.apiKey,
        token: this.$store.getters.jwtToken,
        callbackOK: "onGetSuperAdminGroupSetting",
        callbackExit: "onCallbackExit",
      };
      await this.$refs.httpCommon.HttpGet(_httpGetInfo);
    },

    /* Request Get User Admin */
    _requestGetUserAdmin: async function () {
      let _httpGetInfo = {
        url: this.$store.getters.urlAdminUser + this._getQueryGroupParameter(),
        apikey: this.$store.getters.apiKey,
        token: this.$store.getters.jwtToken,
        callbackOK: "onGetAdminUser",
        callbackExit: "onCallbackExit",
      };
      await this.$refs.httpCommon.HttpGet(_httpGetInfo);
    },

    /* Request Get GeoFence Admin */
    _requestGetGeoFenceAdmin: async function () {
      let _httpGetInfo = {
        url:
          this.$store.getters.urlAdminGeoFence + this._getQueryGroupParameter(),
        apikey: this.$store.getters.apiKey,
        token: this.$store.getters.jwtToken,
        callbackOK: "onGetAdminGeoFence",
        callbackExit: "onCallbackExit",
      };
      await this.$refs.httpCommon.HttpGet(_httpGetInfo);
    },

    /* Request Information Driver */
    _requestInformationDriver: async function () {
      await this._requestGetDriverImeiDefault();
      if (true == this._isError()) return this._closeSnackBarReload();
      this._setGroupListDefault();
      this.$store.commit("information/userList", [
        {
          group: this.$store.getters["userCustomGroup"],
          account: this.$store.getters["userCognitoUsername"],
          role: this.$store.getters["userCustomRole"],
          // attribute: this.$store.getters["information/driverImeiList"]
        },
      ]);
    },

    /* Request Get Driver IMEI Default */
    _requestGetDriverImeiDefault: async function () {
      let _httpGetInfo = {
        url:
          this.$store.getters.urlDriverImei +
          this._getQueryGroupDriverParameter(),
        apikey: this.$store.getters.apiKey,
        token: this.$store.getters.jwtToken,
        callbackOK: "onGetDefaultDriverImei",
        callbackExit: "onCallbackExit",
      };
      await this.$refs.httpCommon.HttpGet(_httpGetInfo);
    },

    /* Request Information Passenger */
    _requestInformationPassenger: async function () {},

    /* External Process Reload Configuration */
    _externalProcessReloadConfiguration: function () {
      if (true == this._isError()) return;

      if ("LoginPage" == this.$route.name)
        if (undefined != this.$refs.refView.pushToMainScreen)
          this.$refs.refView.pushToMainScreen();

      if (undefined != this.$refs.refView.reloadContent)
        this.$refs.refView.reloadContent();
    },

    /********************************* Utility Function ********************************/
    /* Get Query Group Parameter */
    _getQueryGroupParameter: function () {
      return `?group=${this.$store.getters.userCustomGroup}`;
    },

    /* Get Query Group Driver Parameter */
    _getQueryGroupDriverParameter: function () {
      return `?group=${this.$store.getters.userCustomGroup}&driveraccount=${this.$store.getters.userCognitoUsername}`;
    },

    /* Set Flag Error Request */
    _setFlagErrorRequest: function () {
      this.mIsErrorRequest = true;
    },

    /* Display Snack Bar Reload */
    _displaySnackBarReload: function () {
      this.$refs.refSnackBarReload.displayLoadingSnackbar();
    },

    /* Check Empty Object */
    _isEmptyObject: function (object) {
      return 0 === Object.keys(object).length;
    },

    /* Check Error Login */
    _isError: function () {
      if (true == this._isErrorRequest()) return true;
      return false;
    },

    /* Check Error Request */
    _isErrorRequest: function () {
      return true == this.mIsErrorRequest ? true : false;
    },

    /* Close Snack Bar Reload */
    _closeSnackBarReload: function () {
      this.$refs.refSnackBarReload.closeLoadingSnackbar();
    },

    /* Clear Flag Error Request */
    _clearFlagErrorRequest: function () {
      this.mIsErrorRequest = false;
    },

    /* Clear Environment When Reload Configuration */
    _clearEnvironmentWhenReloadConfiguration: function () {
      this._clearFlagErrorRequest();
    },

    /* Clear Stores */
    _clearStores: function () {
      this.$store.commit("resetState");
      this.$store.commit("information/resetState");
      this.$store.commit("setting/resetState");
      this.$store.commit("dataSetting/resetState");
    },
  },
};
</script>

<style>
@import './styles/vts_style.css';
</style>
