/*
|-------------------------------------------------------------------------------
| VUEX modules/configure.store.js
|-------------------------------------------------------------------------------
| The Vuex data store for the Access Feature
*/

const mDefaultState = () => {
  return {
    signedIn: null,
    role: "",
    user: {},
    userCongnitoGroup: [],
    environments: [],
    userId: "",
    username: "",
    idToken: {},
    apiKey: "",
    currentLocation: {},
  };
};

/* Defines the state being monitored for the module. */
const state = mDefaultState();

/* Defines the ters used by the module */
const getters = {
  /* Environment Detail */
  environments: (state) => state.environments,
  userpool: (state) => state.environments.userpool,
  clientId: (state) => state.environments.client_id,
  region: (state) => state.environments.region,
  basePath: (state) => state.environments.base_path,
  vapidKey: (state) => state.environments.vapid_key,
  /* URL Paths */
  urlApikey: (state) => state.environments.url_paths.api_key,
  urlCheckIn: (state) => state.environments.url_paths.check_in,
  urlCheckOut: (state) => state.environments.url_paths.check_out,
  urlDailyReport: (state) => state.environments.url_paths.daily_report,
  urlDriverImei: (state) => state.environments.url_paths.driver_imei,
  urlFuel: (state) => state.environments.url_paths.fuel,
  urlGeoFenceReport: (state) => state.environments.url_paths.geofence_report,
  urlHistory: (state) => state.environments.url_paths.history,
  urlLastestLocation: (state) => state.environments.url_paths.lastest_location,
  urlRegisterNotification: (state) =>
    state.environments.url_paths.register_notification,
  urlRegisterAllowPolicy: (state) =>
    state.environments.url_paths.register_allow_policy,
  urlAllNotification: (state) => state.environments.url_paths.all_notification,
  urlNotificationVersion: (state) =>
    state.environments.url_paths.notification_version,
  urlMaintenance: (state) => state.environments.url_paths.maintenance,
  urlAdminDevice: (state) => state.environments.url_paths.admin_device,
  urlAdminDriverImei: (state) => state.environments.url_paths.admin_driver_imei,
  urlAdminGeoFence: (state) => state.environments.url_paths.admin_geofence,
  urlAdminUser: (state) => state.environments.url_paths.admin_user,
  urlAdminAttendanceSetting: (state) =>
    state.environments.url_paths.admin_attendance_setting,
  urlAdminOverspeed: (state) => state.environments.url_paths.admin_overspeed,
  urlAdminOverstay: (state) => state.environments.url_paths.admin_overstay,
  urlAdminAllNotification: (state) =>
    state.environments.url_paths.admin_all_notification,
  urlAdminNotification: (state) =>
    state.environments.url_paths.admin_notification,
  urlAdminGroupSetting: (state) =>
    state.environments.url_paths.admin_group_setting,
  urlSuperAdminDriverImei: (state) =>
    state.environments.url_paths.super_driver_imei,
  urlSuperAdminGroup: (state) => state.environments.url_paths.super_group,
  urlSuperAdminUser: (state) => state.environments.url_paths.super_user,
  urlSuperAdminGeoFence: (state) => state.environments.url_paths.super_geofence,
  urlSuperAdminLastestLocation: (state) =>
    state.environments.url_paths.super_lastest_location,
  urlSuperAdminAllNotification: (state) =>
    state.environments.url_paths.super_all_notification,
  urlSuperAdminGroupSetting: (state) =>
    state.environments.url_paths.super_group_setting,
  /* User Detail */
  user: (state) => state.user,
  signedIn: (state) => !!state.user,
  userAttributes: (state) => state.user.attributes,
  userId: (state) => state.user.attributes.sub,
  jwtToken: (state) => state.user.signInUserSession.idToken.jwtToken,
  username: (state) => state.user.username,
  userCognitoUsername: (state) =>
    state.user.signInUserSession.idToken.payload["cognito:username"],
  userCognitoUsernameParamQuery: (state) =>
    `?account=${state.user.signInUserSession.idToken.payload["cognito:username"]}`,
  userCognitoGroups: (state) =>
    state.user.signInUserSession.idToken.payload["cognito:groups"],
  userCustomGroup: (state) =>
    state.user.signInUserSession.idToken.payload["custom:group"],
  userCustomRole: (state) =>
    state.user.signInUserSession.idToken.payload["custom:role"],
  userCustomSubServer: (state) =>
    state.user.signInUserSession.idToken.payload["custom:subserver_id"],
  userCustomPackage: (state) =>
    state.user.signInUserSession.idToken.payload["custom:package"],
  isPrivateContent: (state) => {
    let _username =
      state.user.signInUserSession.idToken.payload["cognito:username"];
    if ("micware" == _username) return true;
    let _group = state.user.signInUserSession.idToken.payload["custom:group"];
    if ("map" == _group) return true;
    return false;
  },
  /* API Key */
  apiKey: (state) => state.apiKey,
  /* Current Location */
  currentLocation: (state) => {
    let _lat = state.currentLocation.lat;
    let _lng = state.currentLocation.lng;
    if (undefined == _lat || undefined == _lng)
      return {
        lat: 13.764908,
        lng: 100.538289,
      };
    return state.currentLocation;
  },
};

/* Defines the mutations used */
const mutations = {
  user: (state, payload) => (state.user = payload),
  environments: (state, payload) => (state.environments = payload),
  apiKey: (state, payload) => (state.apiKey = payload),
  currentLocation: (state, payload) => (state.currentLocation = payload),
  /* Reset State */
  resetState: (state) => Object.assign(state, mDefaultState()),
};

/* Export */
export default {
  state,
  getters,
  mutations,
};

/* END OF FILE */
