<template>
<!----------------------------------- Login Main Page ----------------------------------->
  <div id="HttpCommon">
<!----------------------------------- Dialog Maintenance ------------------------------------>
    <dialog-maintenance
      ref="dialogMaintenance"
      v-bind:wordContent="mWordDialogMaintenance"
    />
<!----------------------------------- Dialog Error Retry -------------------------------------->
    <dialogErrorRetry
      ref="dialogErrorRetry"
      v-bind:wordContent="mWordDialogErrorRetry"
      v-bind:isRetryVisible="mRetryDialogErrorRetry"
    />
  </div>
</template>

<script>
/* Import Component */
import dialogErrorRetry from "./dialogErrorRetry.vue";
import dialogMaintenance from "./dialogMaintenanceMode.vue";
import axios from "axios";
import router from "../router";

axios.interceptors.response.use(null, (error) => {
  if (error.response != undefined) {
    return error.response;
  } else {
    return error;
  }
});

/* Constant HTTP Method Type */
const cHttpMethodType = {
  GET: "GET",
  GET_QUERY: "GET_QUERY",
  GET_ENVIRONMENT: "GET_ENVIRONMENT",
  GET_API_KEY: "GET_API_KEY",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  DELETE_QUERY: "DELETE_QUERY",
};

export default {
  /* Name of Login Page */
  name: "HttpCommon",

  /* Components of Login Page */
  components: {
    dialogMaintenance,
    dialogErrorRetry,
  },

  /* Data of Login Page */
  data() {
    return {
      /* Response Request */
      mResponseRequest: {
        status: 0,
        result: {},
      },
      /* Word Dialog Maintenance */
      mWordDialogMaintenance: "",
      /* Word Dialog Error */
      mWordDialogError: "",
      /* Word Dialog Error Retry*/
      mWordDialogErrorRetry: "",
      mRetryDialogErrorRetry: true,
    };
  },
  props: {
    httpGetInfo: {
      url: { type: String },
      token: { type: String },
      apikey: { type: String },
      callbackOK: { type: String },
      callbackNG: { type: String },
      params: {},
    },

    httpGetApiKeyInfo: {
      url: { type: String },
      token: { type: String },
      callbackOK: { type: String },
      callbackNG: { type: String },
      callbackExit: { type: String },
    },

    httpGetEnvironmentInfo: {
      url: { type: String },
      callbackOK: { type: String },
      callbackNG: { type: String },
    },
  },

  /* Method of Http Common */
  methods: {
    CheckHttpResponse: async function (info) {
      if (this.mResponseRequest.status == undefined) {
        /** Call ActionError */
        await this.ActionError(info);
        return;
      } else {
        if (this.mResponseRequest.data == undefined) {
          /** Log error */
          if (
            this.mResponseRequest.status == 401 ||
            this.mResponseRequest.status == 403
          ) {
            /** Unauthorized */
            await this.DisplayErrorUnauthorize();
            return;
          } else {
            /** Call ActionError */
            await this.ActionError(info);
            return;
          }
        } else {
          if (this.mResponseRequest.data.status == 200) {
            /** Emit OK Callback */
            if (info.callbackOK != undefined) {
              this.$emit(info.callbackOK, this.mResponseRequest);
              return;
            }
          } else if (
            this.mResponseRequest.status == 403 &&
            this.mResponseRequest.data.status == 503
          ) {
            /** Maintenance Mode */
            await this.DisplayMaintenanceMode(info);
            return;
          } else if (
            this.mResponseRequest.status == 401 ||
            this.mResponseRequest.status == 403 ||
            this.mResponseRequest.data.status == 401 ||
            this.mResponseRequest.data.status == 403
          ) {
            /** Unauthorized */
            await this.DisplayErrorUnauthorize();
            return;
          } else {
            /** Call ActionError */
            await this.ActionError(info);
            return;
          }
        }
      }
    },

    /* Display Dialog Error */
    DisplayDialogError: async function ({ word }) {
      /* Set Word */
      this.mWordDialogErrorRetry = word;
      this.mRetryDialogErrorRetry = false;
      /* Display Dialog Error */
      return await this.$refs.dialogErrorRetry.displayContent();
    },

    /* Display Dialog Error */
    DisplayDialogErrorRetry: async function ({ word }) {
      /* Set Word */
      this.mWordDialogErrorRetry = word;
      // this.mRetryDialogErrorRetry = !!retry;
      /* Display Dialog Error */
      return await this.$refs.dialogErrorRetry.displayContent();
    },

    /* Display Dialog Maintenance */
    DisplayDialogMaintenance: async function ({ word }) {
      /* Set Word */
      this.mWordDialogMaintenance = word;
      /* Display Dialog Maintenance */
      return await this.$refs.dialogMaintenance.displayContent();
    },

    HttpPost: async function (info) {
      this.$log.info("HttpPost: " + info.url);
      info.method = cHttpMethodType.POST;
      this.mResponseRequest = await axios
        .post(info.url, info.data, {
          headers: {
            Authorization: info.token,
            "X-API-Key": info.apikey,
          },
          params: info.params,
        })
        .then((result) => result)
        .catch((err) => err);
      return await this.CheckHttpResponse(info);
    },

    HttpPut: async function (info) {
      this.$log.info("HttpPut: " + info.url);
      info.method = cHttpMethodType.PUT;
      this.mResponseRequest = await axios
        .put(info.url, info.data, {
          headers: {
            Authorization: info.token,
            "X-API-Key": info.apikey,
          },
          params: info.params,
          data: info.data,
        })
        .then((result) => result)
        .catch((err) => err);
      return await this.CheckHttpResponse(info);
    },

    HttpGet: async function (info) {
      this.$log.info("HttpGet: " + info.url);
      info.method = cHttpMethodType.GET;
      this.mResponseRequest = await axios
        .get(info.url, {
          headers: {
            Authorization: info.token,
            "X-API-Key": info.apikey,
          },
          params: info.params,
        })
        .then((result) => result)
        .catch((err) => err);
      return await this.CheckHttpResponse(info);
    },

    HttpGetQuery: async function (info) {
      this.$log.info("HttpGet: " + info.url);
      info.method = cHttpMethodType.GET_QUERY;
      this.mResponseRequest = await axios
        .get(info.url, {
          headers: {
            Authorization: info.token,
            "X-API-Key": info.apikey,
          },
          params: info.params,
        })
        .then((result) => result)
        .catch((err) => err);
      return await this.CheckHttpResponse(info);
    },

    HttpDelete: async function (info) {
      this.$log.info("HttpDelete: " + info.url);
      let _fullPath = `${info.url}/${info.data.path}`;
      this.$log.info("Full Path of HttpDelete: " + _fullPath);
      info.method = cHttpMethodType.DELETE;
      this.mResponseRequest = await axios
        .delete(_fullPath, {
          headers: {
            Authorization: info.token,
            "X-API-Key": info.apikey,
          },
        })
        .then((result) => result)
        .catch((err) => err);
      return await this.CheckHttpResponse(info);
    },

    HttpDeleteByQuery: async function (info) {
      this.$log.info("HttpDelete: " + info.url);
      let _fullPath = info.url;
      this.$log.info("Full Path of HttpDelete: " + _fullPath);
      info.method = cHttpMethodType.DELETE_QUERY;
      this.mResponseRequest = await axios
        .delete(_fullPath, {
          headers: {
            Authorization: info.token,
            "X-API-Key": info.apikey,
          },
          params: info.params,
        })
        .then((result) => result)
        .catch((err) => err);
      return await this.CheckHttpResponse(info);
    },

    HttpGetEnvironment: async function (info) {
      this.$log.info("HttpGetEnvironment: " + info.url);
      info.method = cHttpMethodType.GET_ENVIRONMENT;
      this.mResponseRequest = await axios
        .get(info.url)
        .then((result) => result)
        .catch((err) => err);
      if (this.mResponseRequest.status == undefined) {
        this.$log.info(this.mResponseRequest);
        this.DisplayDialogError({ word: this.mResponseRequest.message });
      } else {
        if (this.mResponseRequest.status != 200) {
          this.DisplayDialogError({
            word: this.$t("common.messages.internalServerError"),
          });
        } else {
          if (info.callbackOK != undefined) {
            this.$emit(info.callbackOK, this.mResponseRequest);
          }
        }
      }
    },

    HttpGetApiKey: async function (info) {
      this.$log.info("HttpGetApiKey: " + info.url);
      info.method = cHttpMethodType.GET_API_KEY;
      this.mResponseRequest = await axios
        .get(info.url, {
          headers: {
            Authorization: info.token,
          },
        })
        .then((result) => result)
        .catch((err) => err);
      if (this.mResponseRequest.status == undefined) {
        /** Show error dialog : 13EDS */
        await this.DisplayErrorRetryApiKey(info);
        return;
      } else {
        if (this.mResponseRequest.data == undefined) {
          /** Log error */
          this.$log.error(this.mResponseRequest);

          if (
            this.mResponseRequest.status == 401 ||
            this.mResponseRequest.status == 403
          ) {
            /** Unauthorized */
            await this.DisplayErrorUnauthorize();
            return;
          } else {
            /** Show error dialog : 13EDS */
            await this.DisplayErrorRetryApiKey(info);
            return;
          }
        } else {
          if (this.mResponseRequest.data.status == 200) {
            /** Emit OK Callback */
            if (info.callbackOK != undefined) {
              this.$emit(info.callbackOK, this.mResponseRequest);
              return;
            }
          } else if (
            this.mResponseRequest.status == 403 &&
            this.mResponseRequest.data.status == 503
          ) {
            /** Maintenance Mode */
            await this.DisplayMaintenanceMode(info);
            return;
          } else if (
            this.mResponseRequest.status == 403 &&
            (this.mResponseRequest.data.status == 401 ||
              this.mResponseRequest.data.status == 403)
          ) {
            /** Unauthorized */
            await this.DisplayErrorUnauthorize();
            return;
          } else {
            /** Show error dialog : 13EDS */
            await this.DisplayErrorRetryApiKey(info);
            return;
          }
        }
      }
    },

    /**
     * To handle requested output dynamically on requestor side (No UI triggered invole)
     */
    CustomHttpRequest: async function (
      method,
      url,
      headers = {},
      data = {},
      params = {},
      onRequestStart = undefined,
      onRequestSuccess = undefined,
      onRequestError = undefined
    ) {
      this.$log.info("CustomHttpRequest: " + url);

      let requestParams = {
        method: method,
        url: url,
        headers: headers,
        data: data,
        params: params,
      };

      if (onRequestStart) onRequestStart(requestParams);

      try {
        let response = await axios(requestParams);
        if (onRequestSuccess) onRequestSuccess(response);
        return response;
      } catch (err) {
        if (onRequestError) onRequestError(err);
        return err;
      }
    },

    DisplayErrorUnauthorize: async function () {
      /** Log error */
      this.$log.error(this.mResponseRequest);

      //--- (2) Unauthorized: error dialog => close => login screen ----------------------------
      /** Show error dialog */
      let errorDetail;
      if (this.mResponseRequest.data != undefined) {
        errorDetail = this.mResponseRequest.data.message;
      } else {
        errorDetail = this.mResponseRequest.message;
      }
      const closed = await this.DisplayDialogError({
        word: this.$t("common.messages.unauthorized") + "\n" + errorDetail,
      });

      if (closed) {
        this.$log.info("push to LoginPage");
        router.push({ name: "LoginPage" }).catch(() => {});
      }
    },

    DisplayErrorRetryApiKey: async function (info) {
      /** Log error */
      this.$log.error(this.mResponseRequest);

      //--- (1) 13EDS: error dialog => retry ----------------------------
      /** Show error dialog */
      let errorDetail;
      if (this.mResponseRequest.data != undefined) {
        if (undefined == this.mResponseRequest.data.errorMessage)
          errorDetail = this.mResponseRequest.data.message;
        else
          errorDetail = JSON.parse(
            this.mResponseRequest.data.errorMessage
          ).detail;
      } else {
        errorDetail = this.mResponseRequest.message;
      }
      const retry = await this.DisplayDialogErrorRetry({
        word:
          this.$t("common.messages.internalServerError") + "\n" + errorDetail,
      });

      if (retry) {
        await this.HttpGetApiKey(info);
      } else {
        /** Emit Exit Callback */
        if (info.callbackExit != undefined) {
          this.$emit(info.callbackExit, this.mResponseRequest.data.status);
        }
      }
    },

    ActionError: async function (info) {
      /** Log error */
      this.$log.error(this.mResponseRequest);

      /** Emit NG Callback */
      if (info.callbackNG != undefined) {
        this.$emit(info.callbackNG, this.mResponseRequest);
        return;
      }

      /** Show error dialog : 13EDS */
      await this.DisplayErrorRetry(info);
    },

    DisplayErrorRetry: async function (info) {
      /** Emit NG Callback */
      if (info.callbackNG != undefined) {
        this.$emit(info.callbackNG, this.mResponseRequest);
        return;
      }
      //--- (1) 13EDS: error dialog => retry ----------------------------
      /** Show error dialog */
      let errorDetail;
      if (this.mResponseRequest.data != undefined) {
        if (undefined == this.mResponseRequest.data.errorMessage)
          errorDetail = this.mResponseRequest.data.message;
        else
          errorDetail = JSON.parse(
            this.mResponseRequest.data.errorMessage
          ).detail;
      } else {
        errorDetail = this.mResponseRequest.message;
      }

      const retry = await this.DisplayDialogErrorRetry({
        word:
          this.$t("common.messages.internalServerError") + "\n" + errorDetail,
      });

      if (retry) {
        switch (info.method) {
          case cHttpMethodType.GET:
            await this.HttpGet(info);
            break;
          case cHttpMethodType.GET_QUERY:
            await this.HttpGetQuery(info);
            break;
          case cHttpMethodType.POST:
            await this.HttpPost(info);
            break;
          case cHttpMethodType.PUT:
            await this.HttpPut(info);
            break;
          case cHttpMethodType.DELETE:
            await this.HttpDelete(info);
            break;
          case cHttpMethodType.DELETE_QUERY:
            await this.HttpDeleteByQuery(info);
            break;
        }
      } else {
        /** Emit Exit Callback */
        if (info.callbackExit != undefined) {
          this.$emit(info.callbackExit, this.mResponseRequest.data.status);
        }
      }
    },

    DisplayMaintenanceMode: async function (info) {
      //--- (3) Maintenance: dialog => close => login screen ----------------------------
      const message = this.mResponseRequest.data.message;
      // const closed = await this.DisplayDialogMaintenance({ word: this.$t("dialogMaintenanceMode.messages.mantenanceContent") });
      const closed = await this.DisplayDialogMaintenance({ word: message });
      if (closed) {
        if (info.callbackExit != undefined) {
          this.$log.info(info.callbackExit);
          this.$emit(info.callbackExit, this.mResponseRequest.data.status);
        }

        this.$log.info("push to LoginPage");
        router.push({ name: "LoginPage" }).catch(() => {});
      }
    },
  },
};
</script>